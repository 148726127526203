
import React from 'react';
import { ClockIcon, CalendarIcon } from '@heroicons/react/24/outline'; // Ensure you have Heroicons v2

const ScheduleTable = () => {
  return (
    <div className="container mx-auto px-4 py-12 mt-[4%]">
      <div className="text-center mb-12">
        <h2 className="text-2xl lg:text-4xl font-bold text-gray-800 tracking-wide">Office Schedule</h2>
        <p className="text-lg text-gray-600 mt-3">Here is our weekly schedule for your convenience</p>
      </div>

      <div className="w-full">
        <table className="min-w-full bg-white shadow-lg rounded-lg">
          <thead>
            <tr className="bg-gray-800 text-white">
              <th className="text-left py-4 px-6 uppercase font-semibold text-sm">Days</th>
              <th className="text-left py-4 px-6 uppercase font-semibold text-sm">Schedule</th>
            </tr>
          </thead>
          <tbody className="text-gray-800 divide-y divide-gray-200">
            {[
              { day: 'Monday', schedule: '10am – 9PM' },
              { day: 'Tuesday', schedule: '10am – 9PM' },
              { day: 'Wednesday', schedule: '10am – 9PM' },
              { day: 'Thursday', schedule: '10am – 9PM' },
              { day: 'Friday', schedule: '10am – 9PM' },
              { day: 'Saturday', schedule: '10am – 9PM' },
              { day: 'Sunday', schedule: '10am – 9PM' }
            ].map((row, index) => (
              <tr
                key={row.day}
                className={`hover:bg-blue-50 transition-all duration-200 transform hover:scale-[1.02] hover:shadow-md rounded-lg ${
                  index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                }`}
              >
                <td className="text-left py-4 px-6 flex items-center space-x-3">
                  <CalendarIcon className="h-5 w-5 text-indigo-500" />
                  <span className="font-medium text-lg">{row.day}</span>
                </td>
                <td className="text-left py-4 px-6">
                  <div className="flex flex-col">
                    <div className="flex items-center space-x-2">
                      <ClockIcon className="h-5 w-5 text-indigo-500" />
                      <span className="font-medium text-lg">Working Hours</span>
                    </div>
                    <span className="text-md text-gray-600 mt-1">{row.schedule}</span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScheduleTable;
