import React from 'react';

const OnlineBooking = () => {
  const handleButtonClick = () => {
    window.location.href = "https://outlook.office365.com/owa/calendar/BirimoInsuranceAgency1@birimoinsurance.com/bookings/";
  };
  return (
    <div className="container mx-auto px-4 mt-[4%]  w-[98%] text-center">
      {/* Section Header */}
      <h2 className="text-2xl lg:text-4xl  font-bold text-gray-800 mb-4">For Online Booking</h2>
      {/* <p className="text-lg text-gray-600 mb-8">Experience the ease of online booking with just a click.</p> */}

      {/* Button Section */}
      <button
        className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-8 rounded-full transition-all duration-300 transform hover:scale-105 hover:shadow-lg"
        onClick={handleButtonClick}
      >
        Book Now
      </button>
    </div>
  );
};

export default OnlineBooking;
